import * as React from "react";
import { Link } from "gatsby";
import { Card, CardActions, CardContent, Typography } from "@material-ui/core";
import placeholderCard from "../../images/ui/placeholder-card.png";
import { CardRarity } from "./CardRarity";
import { FullSizeCardModal } from "./FullSizeCardModal";
import { FlagIcon } from "../../components/FlagIcon";

export const CardDetails = ({ card, paperClasses = `` }) => {
    const [showFullSizeCard, setShowFullSizeCard] = React.useState(false);
    const openFullSizeCardModal = () => {
        setShowFullSizeCard(true);
    };
    const closeFullSizeCardModal = () => {
        setShowFullSizeCard(false);
    };

    const cardImage = card.image_url
        ? card.image_url.replace(`_hires`, ``)
        : card.image
        ? `${process.env.GATSBY_API_URL}${card.image.data.asset_url}?key=card-medium`
        : placeholderCard;
    const seriesName =
        card.set.series.name !== `-` ? card.set.series.name : `First Edition`;
    const languages =
        card.languages_override && card.languages_override.length > 0
            ? card.languages_override
            : card.set.series.game.languages;
    return (
        <Card
            className={`flex flex-col flex-wrap justify-center sm:flex-row ${paperClasses}`}
        >
            <div className="flex-initial">
                {card.image || card.image_url ? (
                    <button
                        onClick={openFullSizeCardModal}
                        onKeyPress={openFullSizeCardModal}
                    >
                        <img
                            alt={card.name}
                            className={`mx-auto`}
                            height="300"
                            src={cardImage}
                            title={`Show full size card ${card.name}`}
                            width="200"
                        />
                    </button>
                ) : (
                    <img
                        alt={card.name}
                        height="300"
                        src={cardImage}
                        title={card.name}
                        width="200"
                    />
                )}
            </div>
            <div className="flex-auto">
                <CardContent>
                    <Typography component="h5" variant="h5">
                        {card.name}
                        {` `}
                        {card.subtitle ? <>({card.subtitle})</> : null}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle1">
                        {languages.map((lang) => (
                            <FlagIcon className="mr-2" code={lang} key={lang} />
                        ))}
                        <br />
                        Game:{` `}
                        <Link
                            className="text-primary hover:text-primary-light"
                            to={`/games/${card.set.series.game.url_title}`}
                        >
                            {card.set.series.game && card.set.series.game.name}
                        </Link>
                        <br />
                        Series: {seriesName}
                        <br />
                        Set:{` `}
                        <Link
                            className="text-primary hover:text-primary-light"
                            to={`/games/${card.set.series.game.url_title}/${card.set.url_title}`}
                        >
                            {card.set.name}
                        </Link>
                        <br />
                        Type: {card.type}
                        <br />
                        Reference: {card.reference}
                        <br />
                        Rarity:{` `}
                        {
                            <CardRarity
                                rarityNum={card.rarity && card.rarity.id}
                            />
                        }
                    </Typography>
                </CardContent>
                <CardActions></CardActions>
            </div>
            <FullSizeCardModal
                card={card}
                onClose={closeFullSizeCardModal}
                open={showFullSizeCard}
            />
        </Card>
    );
};
